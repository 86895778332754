const isPassesRules = (route, portalConfig) => {
  if (!route.condition) return true;

  const routeForNonIntegrated = route.condition === 'isNonIntegrated';
  const routForIntegrated = route.condition === 'isIntegrated';
  const canCreateTestOrders = route.condition === 'canCreateTestOrders';
  const canEnableInStoreReturns = route.condition === 'canEnableInStoreReturns';
  if (routeForNonIntegrated && portalConfig.isIntegrated) return false;
  if (routForIntegrated && !portalConfig.isIntegrated) return false;
  if (canEnableInStoreReturns && !portalConfig?.inStoreReturnsForm) return false;
  return !(canCreateTestOrders && !portalConfig.canCreateOrdersDirectly);
};

export default function getNavigationRoutes(portalConfig) {
  const routes = portalConfig.features?.navigation || [];
  const filteredRoutes = routes.filter((route) => isPassesRules(route, portalConfig));
  return filteredRoutes.map((route) => {
    if (route.subItems) {
      return {
        ...route,
        subItems: route.subItems.filter((subItem) => isPassesRules(subItem, portalConfig)),
      };
    }
    return route;
  });
}
