const ruleMappings = {
  productCategory: {
    localeString: 'rules.productCategory',
    value: '$.categories',
  },
  productSKU: {
    localeString: 'rules.productSKU',
    value: '$.sku',
  },
  productTags: {
    localeString: 'rules.productTags',
    value: '$.tags',
  },
  productPrice: {
    localeString: 'rules.productPrice',
    value: '$.price',
  },
  purchaseDateFact: {
    localeString: 'rules.orderDate',
    value: '$.purchaseDate',
  },
  equalsOperator: {
    localeString: 'rules.equals',
    value: 'fullMatch',
  },
  notEqualsOperator: {
    localeString: 'rules.notEquals',
    value: 'notFullMatch',
  },
  containsOperator: {
    localeString: 'rules.contains',
    value: 'partialMatch',
  },
  greaterThanOperator: {
    localeString: 'rules.greaterThan',
    value: 'greaterThan',
  },
  lessThanOperator: {
    localeString: 'rules.lessThan',
    value: 'lessThan',
  },
  betweenOperator: {
    localeString: 'rules.between',
    value: 'between',
  },
  purchaseDateAction: {
    localeString: 'rules.orderDate',
    value: 'purchaseDate',
  },
  area: {
    localeString: 'rules.area',
    value: '$.area',
  },

};

export const RuleActionValues = {
  TRUE: 'true',
  FALSE: 'false',
};

const ruleTranslationMappings = Object.entries(ruleMappings).reduce((total, [, { value, localeString }]) => ({
  ...total,
  [value]: localeString,
}), {});
export const RuleTypes = {
  NON_RETURNABLE_ITEM: 'nonReturnableItem',
  RETURN_ADDRESS: 'returnAddress',
  RETURN_WINDOWS: 'withinReturnWindow',
  ORDER_EXCEPTION: 'exceptionAlwaysReturnableItem',
  CARRIER_RULES: 'returnCarrier',
};

const getRuleTranslation = ({ translate, value: key }) => {
  const translationKey = ruleTranslationMappings[key];
  return translationKey ? translate(translationKey) : '';
};

export const getRuleActionValue = ({ rule, warehouses }) => {
  if (rule.ruleType === RuleTypes.RETURN_WINDOWS) {
    const actionPath = rule.rule.event.params.actionValue.returnWindow;
    return {
      days: actionPath?.days,
      from: actionPath?.from,
    };
  }
  if (rule.ruleType === RuleTypes.RETURN_ADDRESS) {
    const warehouse = warehouses.find(
      ({ locationId }) => locationId === rule.rule.event.params.actionValue.locationId,
    );
    return {
      returnTo: warehouse?.locationName,
      locationId: warehouse?.locationId,
    };
  }
  if (rule.ruleType === RuleTypes.CARRIER_RULES) {
    const actionPath = rule.rule.event.params.actionValue.returnCarrier;
    return {
      shouldUse: actionPath?.shouldUse?.toString(),
      carrier: actionPath?.carrier,
      serviceType: actionPath?.serviceType,
    };
  }
  return {};
};

/**
 * Generates payload for rules priority update request.
 *
 * @param {Array} rulesList A list of rules
 * @return {Array} List of { ruleId: string, priority: number } entries
 */
export const generateRulePriorityPayload = (rulesList) => {
  const prioritiesArray = rulesList.map((rule) => rule.rule?.priority).sort((a, b) => b - a);
  return rulesList.map((rule, index) => ({ ruleId: rule.ruleId, priority: prioritiesArray[index] }));
};

export default {
  mappings: ruleMappings,
  getRuleTranslation,
  getRuleActionValue,
  generateRulePriorityPayload,
  RuleTypes,
};
