import React, { useContext } from 'react';
import { LocaleContext } from '@/contexts/locale-context';
import { MerchantContext } from '@/contexts/merchant-context';
import { RoutingContext } from '@/contexts/routing-context';
import { AdminContext } from '@/contexts/admin-context';
import packageJson from '../../../package.json';
import LanguageSelector from '../language-selector/language-selector';

export default function DefaultFooter() {
  const { translate } = useContext(LocaleContext);
  const merchantContext = useContext(MerchantContext);
  const adminContext = useContext(AdminContext);
  const { getFeature } = merchantContext;
  const { getAdminFeature, getCarrierLogo } = adminContext;
  const { showFooter, showVersion, showFooterLogo } = useContext(RoutingContext);
  const { privacyPolicyUrl, termsAndConditionsUrl } = getFeature('footer');
  const { privacyPolicyUrl: adminPrivacy, termsAndConditionsUrl: adminTerms } = getAdminFeature('footer');
  const isMultilanguageSupportEnabled = getAdminFeature('multipleLanguageSupport');

  return (
    <>
      {showVersion() && (
        <div className={`footer-version${showFooter() ? '' : '-footer'}`}>
          <div className="version-wrapper">
            {`${translate('constants.version')} ${packageJson.version}`}
          </div>
        </div>
      )}

      {showFooter()
      && (
      <div className="footer" id="footer">
        <div className="footer-left-section-wrapper">
          {showFooterLogo() && (
          <div className="logo-wrapper">
            <img className="carrier-logo" alt="" src={getCarrierLogo()} />
          </div>
          )}
        </div>
        <div className="footer-right-section-wrapper">
          <div className="footer-link-section">
            {isMultilanguageSupportEnabled && (
              <>
                <LanguageSelector />
                <div className="spacer" />
              </>
            )}
            {(privacyPolicyUrl || adminPrivacy)
            && (
            <>
              <a id="footer-link-privacy" className="footer-link" href={privacyPolicyUrl || adminPrivacy}>{translate('constants.footerPrivacyLabel')}</a>
              <div className="spacer" />
            </>
            )}
            {(termsAndConditionsUrl || adminTerms)
            && (
            <>
              <a id="footer-link-terms" className="footer-link" href={termsAndConditionsUrl || adminTerms}>{translate('constants.footerTermsLabel')}</a>
            </>
            )}
          </div>
        </div>
      </div>
      )}
      <style jsx>
        {`
            .footer {
              align-items: center;
              border-top: 1px solid var(--color-line-grey);
              display: flex;
              flex-basis: 5%;
              flex-direction: row;
              min-height: 50px;
              justify-content: space-between;
              margin: 0 auto;
              width: 100%;
              padding: 0 52px;
              position: fixed;
              bottom: 0;
              background-color: var(--color-white);
            }
            
            .footer-right-section-wrapper {
              align-items: center;
              display: flex;
            }
            
            .footer-link {
              color: var(--color-primary-dark);
              cursor: pointer;
              display: flex;
              font-size: var(--font-size-footer);
              position: relative;
              padding: 3px 5px;
            }
            
            .spacer {
              display: flex;
              justify-content: center;
              width: 24px;
            }
            
            .spacer::before {
              background-color: var(--color-line-grey);
              content: "";
              height: 23px;
              width: 1px;
            }
  
            .footer-link-section {
              display: flex;
              flex-direction: row;
            }

            .footer-version {
                position: fixed;
                bottom: 50px;
                color: var(--color-primary-dark);
                margin: 0 0 var(--spacing-extra-small) 52px;
            }

            .footer-version-footer {
                position: fixed;
                bottom: 5px;
                color: var(--color-primary-dark);
                margin: 0 0 var(--spacing-extra-small) 52px;
            }

            .version-wrapper {
              color: var(--color-dark-grey);
              font-size: var(--font-size-subtitle);
            }

            @media only screen and (max-width: 768px) {
              .footer {
                border-top: none;
              }
  
              .footer,
              .footer-right-section-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
  
              .footer-right-section-wrapper {
                margin: 0 0 20px 0;
              }

              .footer-version {
                bottom: 7px;
                color: var(--color-primary-dark);
                font-size: var(--font-size-H4);
                z-index: var(--zindex-sticky);
                width: 100%;
                margin: 0;
                text-align: center;
              }

              .footer-version-footer {
                position: relative;
                text-align: center;
                margin: 0;
                padding-bottom: 10px;
              }
            }
          `}
      </style>
    </>
  );
}
