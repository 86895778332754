/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Script from 'next/script';
import App from 'next/app';
import '@/utilities/i18n';
import AuthContextProvider from '@/contexts/auth-context';
import AdminContextProvider from '@/contexts/admin-context';
import MerchantContextProvider from '@/contexts/merchant-context';
import LocaleContextProvider from '@/contexts/locale-context';
import ThemeContextProvider from '@/contexts/theme-context';
import ChangesContextProvider from '@/contexts/changes-context';
import ValidationContextProvider from '@/contexts/validation-context';
import ValidateAddressContextProvider from '@/contexts/validate-address-context';
import RoutingContextProvider from '@/contexts/routing-context';
import TelemetryContextProvider from '@/contexts/telemetry-context/telemetry-context';
import FullNavigation from '@/components/full-navigation';
import '../src/styles/globals.css';

function ReturnsPortalAdmin({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Returns Portal Admin</title>
        <meta name="description" content="Returns Portal Admin" />
        <meta name="robots" content="noindex" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />
        <meta name="google" content="notranslate" />

        <link id="favicon" rel="icon shortcut" />
        <link id="apple-touch-icon" rel="apple-touch-icon" />
        <link id="favicon-16x16" rel="icon" type="image/x-icon" />
        <link id="favicon-32x32" rel="icon" type="image/x-icon" />
        <link id="android-chrome-192x192" rel="android-chrome" sizes="192x192" />
        <link id="android-chrome-512x512" rel="android-chrome" sizes="512x512" />
      </Head>

      <main>
        <AuthContextProvider localConfig={pageProps.authData}>
          <AdminContextProvider>
            <ThemeContextProvider>
              <MerchantContextProvider>
                <TelemetryContextProvider>
                  <LocaleContextProvider>
                    <RoutingContextProvider>
                      <ValidateAddressContextProvider>
                        <ChangesContextProvider>
                          <ValidationContextProvider>
                            <FullNavigation>
                              <Component {...pageProps} />
                            </FullNavigation>
                          </ValidationContextProvider>
                        </ChangesContextProvider>
                      </ValidateAddressContextProvider>
                    </RoutingContextProvider>
                  </LocaleContextProvider>
                </TelemetryContextProvider>
              </MerchantContextProvider>
            </ThemeContextProvider>
          </AdminContextProvider>
        </AuthContextProvider>
      </main>
      <div id="absolute-positioned-content" />
      <Script src="/env.js" strategy="beforeInteractive" />
    </>
  );
}

ReturnsPortalAdmin.defaultProps = {
  Component: null,
  pageProps: {},
};

ReturnsPortalAdmin.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.shape({
    authData: PropTypes.shape({
      username: PropTypes.string,
      password: PropTypes.string,
      isAdmin: PropTypes.string,
      rootOrgId: PropTypes.string,
      isDevLogin: PropTypes.bool,
    }),
  }),
};

ReturnsPortalAdmin.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment) {
    appProps.pageProps.authData = {
      username: process.env.REACT_APP_USERNAME,
      password: process.env.REACT_APP_PASSWORD,
      isAdmin: process.env.REACT_APP_IS_ADMIN,
      isDevLogin: true,
    };
  }
  return { ...appProps };
};

export default ReturnsPortalAdmin;
